import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormEditTemplate from "./dtos/IFormEditTemplate";
import IListTemplateCategoryApiProps from "./dtos/IListTemplateCategoryApiProps";
import IOptionSelect from "./dtos/IOptionSelect";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";
import InputSelect from "../../../../../../shared/components/InputSelect";
import InputRadio from "../../../../../../shared/components/InputRadio";
import ChipInput from "../../../../../../shared/components/ChipInput";
import TooltipInformation from "../../../../../../shared/components/TooltipInformation";

import { Notify } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const EditTemplate: React.FC = () => {
  const [optionSelect, setOptionSelect] = useState<Array<IOptionSelect>>([]);
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dataLocation: any = location.state;

  const { getUrl } = useRoutes();

  const { template_id } = useParams();

  useEffect(() => {
    getUrl("/edit-template/:template_id");
  }, []);

  const formSchema = yup.object().shape({
    title: yup.string().required("título é um campo obrigatório"),
    description: yup.string().nullable(),
    key_words: yup
      .array()
      .of(yup.string())
      .required("palavras chave é um campo obrigatório"),
    template_type: yup
      .string()
      .required("tipo do template é um campo obrigatório"),
    is_edit: yup
      .boolean()
      .required("está editavel é um campo obrigatório")
      .typeError("está editavel é um campo obrigatório"),
    is_active: yup
      .boolean()
      .required("está ativo é um campo obrigatório")
      .typeError("está ativo é um campo obrigatório"),
    bits_template_category_id: yup
      .string()
      .required("categorias é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const listTemplateCategory = await api.get(
        "/bits-template-categories/list-all-dashboard"
      );

      let options = [];

      listTemplateCategory.data.forEach(
        (item: IListTemplateCategoryApiProps) => {
          options.push({ label: item.title, value: item.id });
        }
      );

      setOptionSelect(options);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const listTemplateSpecific = await api.get(
        `/bits-templates/${template_id}`
      );

      setTemplate(listTemplateSpecific.data.bits_template.template);

      let isEdit = "false";

      if (listTemplateSpecific.data.bits_template.is_edit === false)
        isEdit = "false";

      if (listTemplateSpecific.data.bits_template.is_edit === true)
        isEdit = "true";

      let isActive = "false";

      if (listTemplateSpecific.data.bits_template.is_active === false)
        isActive = "false";

      if (listTemplateSpecific.data.bits_template.is_active === true)
        isActive = "true";

      let keyWords: Array<string> = [];

      if (listTemplateSpecific.data.bits_template.key_words) {
        keyWords = listTemplateSpecific.data.bits_template.key_words.split(",");
      }

      reset({
        bits_template_category_id:
          listTemplateSpecific.data.bits_template.bits_template_category_id,
        title: listTemplateSpecific.data.bits_template.title,
        description: listTemplateSpecific.data.bits_template.description,
        key_words: keyWords,
        template_type: listTemplateSpecific.data.bits_template.template_type,
        is_edit: isEdit,
        is_active: isActive,
      });
    })();
  }, [template_id, reset]);

  const onSubmit: SubmitHandler<IFormEditTemplate> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        let keyWord = "";

        formData.key_words.forEach((item, index) => {
          if (index < formData.key_words.length - 1) {
            if (item.trim().length !== 0) {
              keyWord += `${item.toLowerCase().trim()},`;
            }
          } else {
            if (item.trim().length !== 0) {
              keyWord += `${item.toLowerCase().trim()}`;
            } else {
              keyWord = keyWord.substring(0, keyWord.length - 1);
            }
          }
        });

        console.log(formData.description);

        const updateTemplate = await api.put("/bits-templates", {
          bits_template_id: template_id,
          bits_template_category_id: formData.bits_template_category_id,
          template: template,
          title: formData.title,
          ...(formData.description && { description: formData.description }),
          key_words: keyWord,
          template_type: formData.template_type,
          is_edit: formData.is_edit,
          is_active: formData.is_active,
        });

        if (updateTemplate.status === 200) {
          Notify({ text: "Template atualizado com sucesso", type: "success" });
          setLoading(false);
          navigate("/templates", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [template_id, navigate, template, dataLocation]
  );

  const templateTypeOption = [
    {
      value: "premium",
      label: "Premium",
    },
    {
      value: "basic",
      label: "Básico",
    },
  ];

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Editar Template" />
      <MasterMain>
        <Main>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Título *</LabelInput>
              <Input
                label="title"
                register={register}
                required={true}
                placeholder="Digite o título"
              />
              {errors.title && <ErrorInput>{errors.title.message}</ErrorInput>}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Descrição</LabelInput>
              <Input
                label="description"
                register={register}
                required={false}
                placeholder="Digite a descrição"
              />
              {errors.description && (
                <ErrorInput>{errors.description.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Palavras chave *</LabelInput>

              <TooltipInformation
                title="A palavra chave é por onde o usuário irá encontrar esse 
                  template. Então adicione o máximo de palavras que achar 
                  necessário. Aperte a tecla enter para adicionar a palavra chave"
                placement="top"
                maxWidth={300}
              />

              <ChipInput
                control={control}
                label="key_words"
                required={true}
                defaultValue={[]}
                placeholder="Palavras chave"
              />
              {errors.key_words && (
                <ErrorInput>{errors.key_words.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Tipo do template *</LabelInput>

              <InputSelect
                defaultValue=""
                control={control}
                label="template_type"
                options={templateTypeOption}
              />
              {errors.template_type && (
                <ErrorInput>{errors.template_type.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Está editavel? *</LabelInput>

              <TooltipInformation
                title="Quando marcado como SIM esse template estará disponível 
                  para ser editado pelos usuários administradores editor da Bits"
                placement="top"
                maxWidth={300}
              />

              <InputRadio
                id="is_edit_true"
                label="is_edit"
                register={register}
                required={true}
                newValue="true"
                text="Sim"
              />
              <InputRadio
                id="is_edit_false"
                label="is_edit"
                register={register}
                required={true}
                newValue="false"
                text="Não"
              />
              {errors.is_edit && (
                <ErrorInput>{errors.is_edit.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Está ativo? *</LabelInput>

              <TooltipInformation
                title="Quando marcado como SIM esse template estará disponível 
                  para uso para todos usuários na plataforma do UX Doc"
                placement="top"
                maxWidth={300}
              />

              <InputRadio
                id="is_active_true"
                label="is_active"
                register={register}
                required={true}
                newValue="true"
                text="Sim"
              />
              <InputRadio
                id="is_active_false"
                label="is_active"
                register={register}
                required={true}
                newValue="false"
                text="Não"
              />
              {errors.is_active && (
                <ErrorInput>{errors.is_active.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Selecione a categoria *</LabelInput>

              <TooltipInformation
                title="Se a categoria que você quer não estiver na lista. Você 
                  deve ir em categoria de templates e cadastrar a categoria que 
                  deseja"
                placement="top"
                maxWidth={300}
              />

              <InputSelect
                defaultValue=""
                control={control}
                label="bits_template_category_id"
                options={optionSelect}
              />
              {errors.bits_template_category_id && (
                <ErrorInput>
                  {errors.bits_template_category_id.message}
                </ErrorInput>
              )}
            </ContainerInput>

            <ButtonCancel
              onClick={() => {
                navigate("/templates", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Salvar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default EditTemplate;
